'use client';

import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./swiper-links.module.scss";
import { FC } from "react";
import { BlogsIcon, BrandsIcon, LicenseIcon, RemIcon, SaleIcon, ShopsIcon } from "../icon";
import { SwiperLink } from "./swiper-link/swiper-link";
import './swiper-links.scss';
const linksList = [{
  name: 'Акции',
  link: '/sales/',
  icon: <SaleIcon />
}, {
  name: 'Магазины',
  link: '/shops/',
  icon: <ShopsIcon />
}, {
  name: 'Remington',
  link: '/remington/',
  icon: <RemIcon />
}, {
  name: 'Блог',
  link: '/blog/',
  icon: <BlogsIcon />
}, {
  name: 'Бренды',
  link: '/brands/',
  icon: <BrandsIcon />
}, {
  name: 'Правила лицензии',
  link: '/license/',
  icon: <LicenseIcon />
}];
export const SwiperLinks: FC = () => {
  return <div className={styles.wrap} data-sentry-component="SwiperLinks" data-sentry-source-file="swiper-links.tsx">
      <Swiper spaceBetween={24} slidesPerView={'auto'} centerInsufficientSlides={true} mousewheel={true} className={"swiper-links"} breakpoints={{
      1559: {
        spaceBetween: 24
      },
      1366: {
        spaceBetween: 24
      },
      1023: {
        direction: "horizontal",
        mousewheel: false
      },
      600: {
        direction: "horizontal",
        spaceBetween: 24,
        mousewheel: false
      },
      425: {
        direction: "horizontal",
        spaceBetween: 24,
        mousewheel: false
      },
      320: {
        direction: "horizontal",
        spaceBetween: 24,
        mousewheel: false
      }
    }} data-sentry-element="Swiper" data-sentry-source-file="swiper-links.tsx">
        {linksList.map((link, index: number) => {
        return <SwiperSlide className={styles.slide} key={`${index + "swiperSlide" + "swiperLinks" + link.name}`}>
              <SwiperLink name={link.name} link={link.link} icon={link.icon} />
            </SwiperSlide>;
      })}
      </Swiper>
    </div>;
};