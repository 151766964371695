import styles from "./season.module.scss";
import "./season.scss";
import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import "swiper/scss";
import Image from "next/image";
import Link from "next/link";
import { ISeason } from "@/services/types/types";
export const Season: FC<ISeason> = ({
  season
}) => {
  return <Swiper className={"swiper-season"} modules={[Pagination, Navigation]} spaceBetween={32} slidesPerView={3} pagination={{
    clickable: true
  }} navigation breakpoints={{
    1559: {
      spaceBetween: 36,
      slidesPerView: 3
    },
    1144: {
      spaceBetween: 32,
      slidesPerView: 2.5
    },
    820: {
      spaceBetween: 32,
      slidesPerView: 2
    },
    500: {
      spaceBetween: 24,
      slidesPerView: 1.5
    },
    320: {
      spaceBetween: 12,
      slidesPerView: 1
    }
  }} data-sentry-element="Swiper" data-sentry-component="Season" data-sentry-source-file="season.tsx">
      {season.map((item: any, seasonIndex: number) => <SwiperSlide className={styles.item} key={`${seasonIndex + "swiperSlide" + "season"}`}>
          <Link prefetch={false} href={`/catalog/${item.code}`}>
            <Image className={styles.img} src={`https://ohotaktiv.ru${item.preview_picture}`} alt={item.name} width={456} height={602} />
          </Link>
          <ul className={styles.list}>
            {item.section_list.map((el: any, index: number) => {
          return <li key={`${index + "sectionList" + "li"}`} className={styles.listItem}>
                  {index !== 0 && <span>/</span>}
                  <Link prefetch={false} href={`/catalog/${el.code}`} className={styles.listLink}>
                    {el.name}
                  </Link>
                </li>;
        })}
          </ul>
        </SwiperSlide>)}
    </Swiper>;
};