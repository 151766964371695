import styles from "./blog.module.scss";
import "./blog.scss";
import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import "swiper/scss";
import { BlogMini } from "@/components/blog-mini/blog-mini";
import { IBlogItem } from "@/services/types/types";
interface IBlogs {
  blogs: Array<IBlogItem>;
}
export const Blog: FC<IBlogs> = ({
  blogs
}) => {
  return <Swiper className="swiper-blog" modules={[Pagination, Navigation]} pagination={{
    clickable: true
  }} navigation breakpoints={{
    1559: {
      spaceBetween: 32,
      slidesPerView: 3
    },
    1439: {
      spaceBetween: 32,
      slidesPerView: 3
    },
    1023: {
      spaceBetween: 32,
      slidesPerView: 3
    },
    500: {
      spaceBetween: 24,
      slidesPerView: 2
    },
    320: {
      spaceBetween: 12,
      slidesPerView: 1
    }
  }} data-sentry-element="Swiper" data-sentry-component="Blog" data-sentry-source-file="blog.tsx">
      {blogs.map((blog: any, index: number) => {
      return <SwiperSlide className={styles.item} key={`${index + "swiperSlide" + "blogkey"}`}>
            <BlogMini className={styles.blog} blog={blog} />
          </SwiperSlide>;
    })}
    </Swiper>;
};