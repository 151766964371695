import Image from "next/image";
import styles from "./story-content.module.scss";
import { FC } from "react";
import { IStoriesElement } from "@/services/types/types";
import Link from "next/link";
import { useAppSelector } from "@/services/redux/hooks";
import { getMutedStatus } from "@/services/redux/features/storiesSlice";
interface IStoryContent {
  isActive: boolean;
  isLoading: boolean;
  stories: Array<IStoriesElement>;
  activeStory: any;
  videoRef: any;
  handleProgress: (arg: any) => void;
  item: any;
}
;
export const StoryContent: FC<IStoryContent> = props => {
  const {
    isActive,
    isLoading,
    stories,
    activeStory,
    videoRef,
    handleProgress,
    item
  } = props;
  const changeOverflow = () => {
    document.body.style.overflow = "auto";
  };
  const isMuted = useAppSelector(getMutedStatus);
  return <>
      {stories.length && isActive && !isLoading ? <div className={styles.story}>
          {stories[activeStory] && stories[activeStory].video ? <video src={`https://ohotaktiv.ru${stories[activeStory].property_file_value}`} onProgress={handleProgress} className={styles.video} preload="metadata" typeof="video/mp4" controls={false} muted={isMuted} ref={videoRef} autoPlay={true} /> : <Image src={`https://ohotaktiv.ru${stories[activeStory].property_file_value}`} className={styles.image} height={400} width={400} alt={"alt"} />}
          <div className={styles.story_info}>
            {stories[activeStory].preview_text && <p className={styles.title}>
                {stories[activeStory].name}
              </p>}
            {stories[activeStory].preview_text && <p className={styles.subtitle}>
                {stories[activeStory].preview_text}
              </p>}
            {!!stories[activeStory].property_name_button_value && <Link prefetch={false} href={stories[activeStory].property_url_button_value} onClick={changeOverflow} className={styles.link}>
                {stories[activeStory].property_name_button_value}
              </Link>}
          </div>
        </div> : <div className={styles.story_not__active}>
          <Image className={styles.image} alt={"alt"} src={`https://ohotaktiv.ru${item.detail_picture}`} width={400} height={400} />
          {activeStory.name}
        </div>}
    </>;
};