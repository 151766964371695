import { StoriesItem } from "../stories-popup/stories-item/stories-item";
import styles from "./stories-mobile-popup.module.scss";
import { FC, TouchEvent, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube } from 'swiper/modules';
import "swiper/scss";
import "./stories-mobile-popup.module.scss";
import "swiper/scss/effect-cube";
import "swiper/scss/effect-flip";
import "swiper/scss/effect-fade";
import { IAllStories } from "@/services/types/types";
interface IStoriesMobilePopup {
  onClose: () => void;
  focusedElem: number;
  storiesList: Array<IAllStories>;
}
;
export const StoriesMobilePopup: FC<IStoriesMobilePopup> = props => {
  const {
    onClose = () => {},
    focusedElem = 0,
    storiesList = []
  } = props;
  const [focus, setFocus] = useState<number>(focusedElem);
  // swipe onClose state
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [swipeDistance, setSwipeDistance] = useState<number | '200%'>(0);
  const [opacityCounter, setOpacityCounter] = useState<number>(5);
  useEffect(() => {
    if (ref.current) {
      ref.current.swiper.slideTo(focusedElem, 0);
    }
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        onClose();
      }
    });
  }, []);
  const ref = useRef(null);
  const nextSlide = () => {
    if (ref.current) {
      ref.current.swiper.slideNext(1000, true);
    }
  };
  const prevSlide = () => {
    if (ref.current) {
      ref.current.swiper.slidePrev(1000, true);
    }
  };
  const slideTo = (slideIndex: number) => {
    if (ref.current) {
      ref.current.swiper.slideTo(slideIndex, 1000, true);
    }
  };
  const onTouchStart = (e: TouchEvent | any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };
  const onTouchMove = (e: TouchEvent | any) => {
    setTouchEnd(e.targetTouches[0].clientX);
    if (!touchStart) return;
    const distance = e.targetTouches[0].clientY - touchStart;
    if (distance < 0) {
      setSwipeDistance(distance / 100);
      return;
    }
    setSwipeDistance(distance);
    const opacity = 0.5 - distance / 100 / 10;
    setOpacityCounter(opacity);
  };
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    if (typeof swipeDistance === 'number' && swipeDistance > 200) {
      setSwipeDistance('200%');
      const timer = setTimeout(() => {
        onClose();
      }, 222);
      return () => clearTimeout(timer);
    } else {
      setSwipeDistance(0);
    }
  };
  return <div className={styles.wrap} data-sentry-component="StoriesMobilePopup" data-sentry-source-file="stories-mobile-popup.tsx">
      <div className={styles.content} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}
    // onMouseDown={onTouchStart}
    // onMouseMove={onTouchMove}
    // onMouseLeave={onTouchEnd}
    style={{
      marginTop: swipeDistance,
      transition: typeof swipeDistance === 'number' && swipeDistance > 100 || swipeDistance !== '200%' ? '' : 'all 0.3s ease',
      backgroundColor: `rgba(37, 37, 37, ${opacityCounter})`
    }}>
        <Swiper centeredSlides={true} className="swiper-stories-mobile" slidesPerView={1} spaceBetween={0} speed={555} ref={ref} onSlideChange={() => setFocus(ref.current.swiper.activeIndex)} modules={[EffectCube]} effect="cube" data-sentry-element="Swiper" data-sentry-source-file="stories-mobile-popup.tsx">
          {storiesList.map((slideContent, index) => <SwiperSlide key={`${index + "swiperMobile" + "slide"}`} className={styles["swiper-slide-active"]} onClick={e => e.stopPropagation()}>
              <StoriesItem key={`${index + "swiperMobile" + "item" + slideContent.name}`} item={slideContent} isActive={focus === index} nextSlide={nextSlide} prevSlide={prevSlide} onClose={onClose} index={index} handleSlide={slideTo} isNext={storiesList[focus + 1] ? true : false} />
            </SwiperSlide>)}
        </Swiper>
      </div>
    </div>;
};