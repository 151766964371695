import Image from 'next/image';
import styles from './blog-mini.module.scss';
import { FC } from 'react';
import { IBlogItemMini } from '@/services/types/types';
import Link from 'next/link';
import classNames from 'classnames';
import { DateIcon } from '../icon';
export const BlogMini: FC<IBlogItemMini> = ({
  blog,
  className
}) => {
  const [year, month, day] = blog.date.split('.');
  const wrapBlogClassnames = classNames({
    [styles.wrap]: true,
    [className!]: className
  });
  const decodedName = decodeURI(blog.name);
  const decodedDescription = decodeURI(blog.description);
  return <div className={wrapBlogClassnames} data-sentry-component="BlogMini" data-sentry-source-file="blog-mini.tsx">
      <p className={styles.label}>{blog.is_article}</p>
      <Image className={styles.img} alt={blog.name} src={`https://ohotaktiv.ru${blog.pic}`} width={1050} height={590} data-sentry-element="Image" data-sentry-source-file="blog-mini.tsx" />
      <div className={styles.description}>
        {/* <Date date={blog.date} icon={true} className={styles.date} /> */}
        <p className={styles.date}>
          <DateIcon data-sentry-element="DateIcon" data-sentry-source-file="blog-mini.tsx" />
          {`${day}.${month}.${year}`}
        </p>
        <p className={styles.title} dangerouslySetInnerHTML={{
        __html: decodedName
      }} />
        <p className={styles.text} dangerouslySetInnerHTML={{
        __html: decodedDescription
      }} />
        {blog.tag ? <ul className={styles.tags}>
            {blog.tag.split(',').map((tag: string, index: number) => {
          return <li key={`${index + 'blogMini' + 'blogtag' + 'li'}`} className={styles.tag}>
                  #{tag.trim()}
                </li>;
        })}
          </ul> : null}
      </div>
      <Link prefetch={false} href={`/blog/${blog.code}`} className={styles.link} data-sentry-element="Link" data-sentry-source-file="blog-mini.tsx">
        {blog.name}
      </Link>
    </div>;
};