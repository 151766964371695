"use client";

import { useAppSelector } from "@/services/redux/hooks";
import styles from "./viewed.module.scss";
import { FC, useEffect, useState } from "react";
import { getViewedList } from "@/services/redux/features/viewedSlice";
import { SwiperCollection } from "../swiper-collection/swiper-collection";
import { getItemsList } from "@/utils/api/catalog";
export const Viewed: FC = () => {
  const [viewedList, setViewedList] = useState<[]>([]);
  const viewed = useAppSelector(getViewedList);
  useEffect(() => {
    viewed.length && getItemsList(viewed.map(item => item).join(","), true, false, true).then(res => {
      setViewedList(res.items.items);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return viewedList && viewedList.length > 0 && <section>
        <h2 className={styles.title}>Недавно просмотренные</h2>
        <SwiperCollection items={viewedList} />
      </section>;
};