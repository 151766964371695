import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/scss/pagination";
import "swiper/scss/navigation";
import "swiper/scss";
import { HitsItem } from "./hits-item/hits-item";
import "./hits-list.scss";
interface IItem {
  id: number;
  name: string;
  preview_picture: string;
  discount_percent: number;
  labels: {
    bestseller: string;
    discount: string;
  };
  reviews: number | string;
  available: boolean;
}
interface IHitsList {
  items: Array<IItem>;
}
export const HitsList: FC<IHitsList> = ({
  items
}) => {
  const breakpoints = {
    1439: {
      spaceBetween: 56,
      slidesPerView: 2
    },
    1365: {
      spaceBetween: 56,
      slidesPerView: 2
    },
    1023: {
      spaceBetween: 34,
      slidesPerView: 2
    },
    767: {
      spaceBetween: 12,
      slidesPerView: 2
    },
    630: {
      spaceBetween: 12,
      slidesPerView: 1
    },
    320: {
      spaceBetween: 12,
      slidesPerView: 1
    }
  };
  return <Swiper className={"swiper-hits"} modules={[Navigation, Pagination, Autoplay]} spaceBetween={56} slidesPerView={2} pagination={{
    clickable: true
  }} navigation breakpoints={breakpoints} autoplay={{
    delay: 5000,
    disableOnInteraction: false
  }} data-sentry-element="Swiper" data-sentry-component="HitsList" data-sentry-source-file="hits-list.tsx">
      {items.map((item: IItem, index: number) => {
      return <SwiperSlide key={`${index + "swiperSlide" + "Hitlist"}`}>
            <HitsItem card={item} />
          </SwiperSlide>;
    })}
    </Swiper>;
};