"use client";

import styles from "./stories-item.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { getHui } from "@/utils/api/getStories";
import { IStoriesElement } from "@/services/types/types";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import { addToViewedStories, getMutedStatus, setMuted, setUnMuted } from "@/services/redux/features/storiesSlice";
import { Range } from "./range/range";
import { MobileAction } from "./mobile-actions/mobile-action";
import { StoryContent } from "./story-content/story-content";
interface IStoriesItem {
  item?: any;
  isActive?: boolean;
  nextSlide?: () => void;
  prevSlide?: () => void;
  onClose?: () => void;
  isNext?: boolean;
  handleSlide?: (arg: number) => void;
  index?: number;
}
export const StoriesItem: FC<IStoriesItem> = props => {
  const {
    item = {},
    isActive = false,
    nextSlide = () => {},
    prevSlide = () => {},
    onClose = () => {},
    handleSlide = () => {},
    isNext = false,
    index = 0
  } = props;
  const [stories, setStories] = useState<IStoriesElement[]>([]);
  const [activeStory, setActiveStory] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [duration, setDuration] = useState<number>(10000);
  const [isVideo, setIsVideo] = useState<boolean>(false);
  const [videoLoad, setVideoLoad] = useState<boolean>(true);
  const [videoProgress, setVideoProgress] = useState<number>(0);
  const durationRef = useRef<number>(10000);
  const dispatch = useAppDispatch();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isMuted = useAppSelector(getMutedStatus);
  const storiesData = (id: string | number) => {
    getHui(id).then(res => {
      if (res.success) {
        setStories(res.items);
        setIsLoading(false);
      }
    });
  };
  const nextStory = () => {
    if (isLoading) return;
    if (!isActive) return;
    if (!!stories[activeStory + 1]) {
      setActiveStory(activeStory + 1);
    } else if (isNext) {
      nextSlide();
      ym(42989679, "reachGoal", "cardFromStories");
      setVideoProgress(0);
      setDuration(0);
      setIsVideo(false);
      dispatch(addToViewedStories(item.id));
    } else {
      onClose();
      setVideoProgress(0);
      setDuration(0);
      setIsVideo(false);
      dispatch(addToViewedStories(item.id));
    }
  };
  const prevStory = () => {
    if (isLoading) return;
    if (!isActive) return;
    if (!!stories[activeStory - 1]) {
      setActiveStory(activeStory - 1);
      setVideoProgress(0);
      setDuration(0);
      setIsVideo(false);
    } else {
      setVideoProgress(0);
      setDuration(0);
      setIsVideo(false);
      prevSlide();
    }
  };
  const handleMuted = () => {
    if (isMuted) {
      dispatch(setUnMuted(false));
      const audioElements = document.querySelectorAll("video");
      audioElements.forEach(audio => {
        audio.muted = false;
      });
    } else {
      const audioElements = document.querySelectorAll("video");
      audioElements.forEach(audio => {
        audio.muted = true;
      });
      dispatch(setMuted(true));
    }
  };
  const handleProgress = (event: any) => {
    const video = event.target;
    const percent = video.currentTime / video.duration * 100;
    setDuration(percent);
  };
  const slideTo = (slideIndex: number) => {
    if (isLoading) return;
    if (isActive) return;
    handleSlide(slideIndex);
  };
  useEffect(() => {
    if (isActive) {
      const timer = setTimeout(() => {
        storiesData(item.id);
        setActiveStory(0);
        setVideoProgress(0);
        setDuration(0);
        setIsVideo(false);
      }, 1111);
      return () => clearTimeout(timer);
    }
  }, [isActive]);
  useEffect(() => {
    if (isLoading) return;
    if (stories) {
      const duration = stories ? stories[activeStory].property_duration_value : 0;
      durationRef.current = Number(duration) * 1000;
    }
    if (stories[activeStory].video) {
      setIsVideo(true);
      return;
    }
    const interval = setInterval(() => {
      setVideoProgress(0);
      setDuration(0);
      setIsVideo(false);
      ym(42989679, "reachGoal", "cardFromStories");
      nextStory();
    }, durationRef.current - durationRef.current * 0.16);
    return () => clearInterval(interval);
  }, [durationRef.current, activeStory, isLoading, isActive]);
  const updateTime = () => {
    const video = videoRef.current;
    if (video) {
      if (video.currentTime / video.duration * 100 === 100) {
        setVideoProgress(0);
        setDuration(0);
        setIsVideo(false);
        ym(42989679, "reachGoal", "cardFromStories");
        nextStory();
        return;
      } else {
        setVideoProgress(video.currentTime / video.duration * 100);
        setDuration(video.duration);
      }
    }
  };
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.addEventListener("timeupdate", updateTime);
      setVideoLoad(true);
      return () => {
        video.removeEventListener("timeupdate", updateTime);
      };
    }
  }, [videoRef && videoRef.current, duration, videoProgress, activeStory]);
  useEffect(() => {
    const video = document.querySelector("video");
    if (!video) return;
    video.onwaiting = event => {
      setVideoLoad(true);
    };
    video.onplaying = event => {
      setVideoLoad(false);
    };
    if (videoLoad) {
      const switchToNext = setTimeout(() => {
        nextStory();
      }, 15000);
      return () => clearTimeout(switchToNext);
    }
  }, [videoProgress, isLoading, videoLoad]);
  return <div className={`${styles.focused} ${!isActive && styles.item}`} onClick={() => slideTo(index)} data-sentry-component="StoriesItem" data-sentry-source-file="stories-item.tsx">
      <div className={styles.wrap}>
        <Range videoProgress={videoProgress} activeStory={activeStory} durationRef={durationRef} isLoading={isLoading} isActive={isActive} stories={stories} data-sentry-element="Range" data-sentry-source-file="stories-item.tsx" />
        <MobileAction handleMuted={handleMuted} isMuted={isMuted} onClose={onClose} isActiveStory={isActive} data-sentry-element="MobileAction" data-sentry-source-file="stories-item.tsx" />
        <div className={`${styles.content}`}>
          <StoryContent handleProgress={handleProgress} activeStory={activeStory} isLoading={isLoading} isActive={isActive} videoRef={videoRef} stories={stories} item={item} data-sentry-element="StoryContent" data-sentry-source-file="stories-item.tsx" />
          <div className={styles.buttons}>
            <button onClick={prevStory} className={styles.prev_slide} />
            {!videoLoad && isVideo && <div className={styles.loader}></div>}
            <button onClick={nextStory} className={styles.next_slide} />
          </div>
        </div>
        <div className={styles.prev_stories} onClick={prevStory} />
        <div className={styles.next_stories} onClick={nextStory} />
      </div>
    </div>;
};