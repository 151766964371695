"use client";

import { FC } from "react";
import { Banners } from "./banners/banners";
import styles from "./index-component.module.scss";
import { Categories } from "./categories/categories";
import { Season } from "./season/season";
import { Viewed } from "../viewed/viewed";
import Link from "next/link";
import ComissionBanner from "../../assets/images/comission-banner-index.webp";
import ComissionBannerMob from "../../assets/images/comission-banner-mob-index.webp";
import { Sales } from "./sales/sales";
import { BrandsPopular } from "../brands-popular/brands-popular";
import { Blog } from "./blog/blog";
import { About } from "./about/about";
import { Hits } from "./hits/hits";
import { Picture } from "../picture/picture";
import { IAllStories, IBlogItem, IBrands, ICardMini, ICategory } from "@/services/types/types";
import { SwiperCardList } from "../swiper-card-list/swiper-card-list";
import { Stories } from "../stories/stories";
import { SwiperLinks } from "../swiper-links/swiper-links";
import { PersonalRecommendedComponent } from "../personal-recommended-component/personal-recommended-component";
interface IIndexComponent {
  banners: any;
  hits: Array<ICardMini>;
  recommended: Array<ICardMini>;
  categories: Array<ICategory>;
  season: any;
  newItems: Array<ICardMini>;
  sales: any;
  brands: Array<IBrands>;
  blogs: Array<IBlogItem>;
  weekendHits?: any;
  futureSales?: any;
  stories: IAllStories[];
}
export const IndexComponent: FC<IIndexComponent> = props => {
  const {
    banners,
    hits,
    recommended,
    categories,
    season,
    newItems,
    sales,
    brands,
    blogs,
    weekendHits,
    stories,
    futureSales
  } = props;
  return <main className={styles.main} id="main" data-sentry-component="IndexComponent" data-sentry-source-file="index-component.tsx">
      <Banners banners={banners} data-sentry-element="Banners" data-sentry-source-file="index-component.tsx" />
      <Stories stories={stories} data-sentry-element="Stories" data-sentry-source-file="index-component.tsx" />
      <div className="container">
        {/* <FutureSales futureSales={futureSales} /> */}
        <Hits hits={weekendHits} data-sentry-element="Hits" data-sentry-source-file="index-component.tsx" />
        <section>
          <SwiperLinks data-sentry-element="SwiperLinks" data-sentry-source-file="index-component.tsx" />
        </section>
        <section className={styles.categories}>
          <h2 className={styles.title}>Популярные категории</h2>
          <Categories categories={categories} data-sentry-element="Categories" data-sentry-source-file="index-component.tsx" />
        </section>
        {/* <UltraPopupVideo /> */}
        <SwiperCardList isVisibleTitle hasLink title={"Хиты продаж"} items={hits} link={"/collection/top-tovary/"} data-sentry-element="SwiperCardList" data-sentry-source-file="index-component.tsx" />
        <section className={styles.sales}>
          <h2 className={styles.title}>
            <Link prefetch={false} href={`/sales`} data-sentry-element="Link" data-sentry-source-file="index-component.tsx">
              Акции
            </Link>
            <Link prefetch={false} className={styles.titleLink} href={`/sales`} data-sentry-element="Link" data-sentry-source-file="index-component.tsx">
              смотреть
            </Link>
          </h2>
          <Sales sales={sales} data-sentry-element="Sales" data-sentry-source-file="index-component.tsx" />
        </section>
        <SwiperCardList isVisibleTitle hasLink title={"Новинки"} items={newItems} link={"/new"} data-sentry-element="SwiperCardList" data-sentry-source-file="index-component.tsx" />
        <section className={styles.season}>
          <h2 className={styles.title}>Готовимся к сезону</h2>
          <Season season={season} data-sentry-element="Season" data-sentry-source-file="index-component.tsx" />
        </section>
        <SwiperCardList isVisibleTitle hasLink={false} title={"Рекомендуем"} items={recommended} data-sentry-element="SwiperCardList" data-sentry-source-file="index-component.tsx" />
        <section className={styles.nested}>
          <h2 className={"visually-hidden"}>Продавай оружие с ОхотАктив</h2>
          <Link prefetch={false} href={"/catalog/comission"} data-sentry-element="Link" data-sentry-source-file="index-component.tsx">
            <Picture className={styles.nestedBanner} alt={"Продавай оружие с ОхотАктив"} width={1450} height={154} desktop={ComissionBanner.src} mobile={ComissionBannerMob.src} data-sentry-element="Picture" data-sentry-source-file="index-component.tsx" />
          </Link>
        </section>
        <PersonalRecommendedComponent data-sentry-element="PersonalRecommendedComponent" data-sentry-source-file="index-component.tsx" />
        <BrandsPopular brands={brands} data-sentry-element="BrandsPopular" data-sentry-source-file="index-component.tsx" />
        <section className={styles.blog}>
          <h2 className={styles.title}>
            <Link prefetch={false} href={`/blog`} data-sentry-element="Link" data-sentry-source-file="index-component.tsx">
              Блог
            </Link>
            <Link prefetch={false} className={styles.titleLink} href={`/blog`} data-sentry-element="Link" data-sentry-source-file="index-component.tsx">
              смотреть
            </Link>
          </h2>
          <Blog blogs={blogs} data-sentry-element="Blog" data-sentry-source-file="index-component.tsx" />
        </section>
        <Viewed data-sentry-element="Viewed" data-sentry-source-file="index-component.tsx" />
        <About data-sentry-element="About" data-sentry-source-file="index-component.tsx" />
      </div>
    </main>;
};