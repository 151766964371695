"use client";

import moment from "moment";
import styles from "./timer.module.scss";
import { FC, useEffect, useState } from "react";
export const Timer: FC<any> = ({
  activeTo
}) => {
  const [time, setTime] = useState(moment(activeTo).diff(moment.now()));
  const [days, setDays] = useState(Math.floor(time / (1000 * 60 * 60 * 24)));
  const [hours, setHours] = useState(Math.floor(time / (1000 * 60 * 60) % 24));
  const [minutes, setMinutes] = useState(Math.floor(time / 1000 / 60 % 60));
  const [seconds, setSeconds] = useState(Math.floor(time / 1000 % 60));
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(moment(activeTo).diff(moment.now()));
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor(time / (1000 * 60 * 60) % 24));
      setMinutes(Math.floor(time / 1000 / 60 % 60));
      setSeconds(Math.floor(time / 1000 % 60));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [time, days, hours, minutes, seconds, activeTo]);
  return <div className={styles.timer} data-sentry-component="Timer" data-sentry-source-file="timer.tsx">
      <div className={styles.timerItems}>
        <div className={styles.timerItem}>{isClient ? days : 0}</div>
        <div className={styles.timerItem}>{isClient ? hours : 0}</div>
        <div className={styles.timerItem}>{isClient ? minutes : 0}</div>
        <div className={styles.timerItem}>{isClient ? seconds : 0}</div>
      </div>
    </div>;
};